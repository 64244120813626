import React from "react";
import './Skills.css'

function Skills() {
    return (
        <div className="carousel carousel--logos">
		<div className="carousel__tape">
            <span><img src="/icons/angularLogo.webp" alt="angularLogo" /></span>
            <span><img src="/icons/bootstrapLogo.webp" alt="bootstrapLogo" /></span>
            <span><img src="/icons/cLogo.webp" alt="cLogo" /></span>
            <span><img src="/icons/confluenceLogo.webp" alt="confluenceLogo" /></span>
            <span><img src="/icons/cppLogo.webp" alt="cppLogo" /></span>
            <span><img src="/icons/cssLogo.webp" alt="cssLogo" /></span>
            <span><img src="/icons/hibernateLogo.webp" alt="hibernateLogo" /></span>
            <span><img src="/icons/htmlLogo.webp" alt="htmlLogo" /></span>
            <span><img src="/icons/javaLogo.webp" alt="javaLogo" /></span>
            <span><img src="/icons/jiraLogo.webp" alt="jiraLogo" /></span>
            <span><img src="/icons/jsLogo.webp" alt="jsLogo" /></span>
            <span><img src="/icons/mongodbLogo.webp" alt="mongodbLogo" /></span>
            <span><img src="/icons/mysqlLogo.webp" alt="mysqlLogo" /></span>
            <span><img src="/icons/nodejsLogo.webp" alt="nodejsLogo" /></span>
            <span><img src="/icons/noSQLLogo.webp" alt="noSQLLogo" /></span>
            <span><img src="/icons/pythonLogo.webp" alt="pythonLogo" /></span>
            <span><img src="/icons/reactLogo.webp" alt="reactLogo" /></span>
            <span><img src="/icons/reduxLogo.webp" alt="reduxLogo" /></span>
            <span><img src="/icons/sonarcubeLogo.webp" alt="sonarcubeLogo" /></span>
            <span><img src="/icons/springbootLogo.webp" alt="springbootLogo" /></span>
            <span><img src="/icons/sqlLogo.webp" alt="sqlLogo" /></span>
            <span><img src="/icons/swaggerLogo.webp" alt="swaggerLogo" /></span>
            <span><img src="/icons/threejsLogo.webp" alt="threejsLogo" /></span>
            <span><img src="/icons/tsedLogo.webp" alt="tsedLogo" /></span>
            <span><img src="/icons/angularLogo.webp" alt="angularLogo" /></span>
            <span><img src="/icons/bootstrapLogo.webp" alt="bootstrapLogo" /></span>
            <span><img src="/icons/cLogo.webp" alt="cLogo" /></span>
            <span><img src="/icons/confluenceLogo.webp" alt="confluenceLogo" /></span>
            <span><img src="/icons/cppLogo.webp" alt="cppLogo" /></span>
            <span><img src="/icons/cssLogo.webp" alt="cssLogo" /></span>
            <span><img src="/icons/hibernateLogo.webp" alt="hibernateLogo" /></span>
            <span><img src="/icons/htmlLogo.webp" alt="htmlLogo" /></span>
            <span><img src="/icons/javaLogo.webp" alt="javaLogo" /></span>
            <span><img src="/icons/jiraLogo.webp" alt="jiraLogo" /></span>
            <span><img src="/icons/jsLogo.webp" alt="jsLogo" /></span>
            <span><img src="/icons/mongodbLogo.webp" alt="mongodbLogo" /></span>
            <span><img src="/icons/mysqlLogo.webp" alt="mysqlLogo" /></span>
            <span><img src="/icons/nodejsLogo.webp" alt="nodejsLogo" /></span>
            <span><img src="/icons/noSQLLogo.webp" alt="noSQLLogo" /></span>
            <span><img src="/icons/pythonLogo.webp" alt="pythonLogo" /></span>
            <span><img src="/icons/reactLogo.webp" alt="reactLogo" /></span>
            <span><img src="/icons/reduxLogo.webp" alt="reduxLogo" /></span>
            <span><img src="/icons/sonarcubeLogo.webp" alt="sonarcubeLogo" /></span>
            <span><img src="/icons/springbootLogo.webp" alt="springbootLogo" /></span>
            <span><img src="/icons/sqlLogo.webp" alt="sqlLogo" /></span>
            <span><img src="/icons/swaggerLogo.webp" alt="swaggerLogo" /></span>
            <span><img src="/icons/threejsLogo.webp" alt="threejsLogo" /></span>
            <span><img src="/icons/tsedLogo.webp" alt="tsedLogo" /></span>	
		</div>
		<div className="carousel__tape">
        <span><img src="/icons/angularLogo.webp" alt="angularLogo" /></span>
            <span><img src="/icons/bootstrapLogo.webp" alt="bootstrapLogo" /></span>
            <span><img src="/icons/cLogo.webp" alt="cLogo" /></span>
            <span><img src="/icons/confluenceLogo.webp" alt="confluenceLogo" /></span>
            <span><img src="/icons/cppLogo.webp" alt="cppLogo" /></span>
            <span><img src="/icons/cssLogo.webp" alt="cssLogo" /></span>
            <span><img src="/icons/hibernateLogo.webp" alt="hibernateLogo" /></span>
            <span><img src="/icons/htmlLogo.webp" alt="htmlLogo" /></span>
            <span><img src="/icons/javaLogo.webp" alt="javaLogo" /></span>
            <span><img src="/icons/jiraLogo.webp" alt="jiraLogo" /></span>
            <span><img src="/icons/jsLogo.webp" alt="jsLogo" /></span>
            <span><img src="/icons/mongodbLogo.webp" alt="mongodbLogo" /></span>
            <span><img src="/icons/mysqlLogo.webp" alt="mysqlLogo" /></span>
            <span><img src="/icons/nodejsLogo.webp" alt="nodejsLogo" /></span>
            <span><img src="/icons/noSQLLogo.webp" alt="noSQLLogo" /></span>
            <span><img src="/icons/pythonLogo.webp" alt="pythonLogo" /></span>
            <span><img src="/icons/reactLogo.webp" alt="reactLogo" /></span>
            <span><img src="/icons/reduxLogo.webp" alt="reduxLogo" /></span>
            <span><img src="/icons/sonarcubeLogo.webp" alt="sonarcubeLogo" /></span>
            <span><img src="/icons/springbootLogo.webp" alt="springbootLogo" /></span>
            <span><img src="/icons/sqlLogo.webp" alt="sqlLogo" /></span>
            <span><img src="/icons/swaggerLogo.webp" alt="swaggerLogo" /></span>
            <span><img src="/icons/threejsLogo.webp" alt="threejsLogo" /></span>
            <span><img src="/icons/tsedLogo.webp" alt="tsedLogo" /></span>
            <span><img src="/icons/angularLogo.webp" alt="angularLogo" /></span>
            <span><img src="/icons/bootstrapLogo.webp" alt="bootstrapLogo" /></span>
            <span><img src="/icons/cLogo.webp" alt="cLogo" /></span>
            <span><img src="/icons/confluenceLogo.webp" alt="confluenceLogo" /></span>
            <span><img src="/icons/cppLogo.webp" alt="cppLogo" /></span>
            <span><img src="/icons/cssLogo.webp" alt="cssLogo" /></span>
            <span><img src="/icons/hibernateLogo.webp" alt="hibernateLogo" /></span>
            <span><img src="/icons/htmlLogo.webp" alt="htmlLogo" /></span>
            <span><img src="/icons/javaLogo.webp" alt="javaLogo" /></span>
            <span><img src="/icons/jiraLogo.webp" alt="jiraLogo" /></span>
            <span><img src="/icons/jsLogo.webp" alt="jsLogo" /></span>
            <span><img src="/icons/mongodbLogo.webp" alt="mongodbLogo" /></span>
            <span><img src="/icons/mysqlLogo.webp" alt="mysqlLogo" /></span>
            <span><img src="/icons/nodejsLogo.webp" alt="nodejsLogo" /></span>
            <span><img src="/icons/noSQLLogo.webp" alt="noSQLLogo" /></span>
            <span><img src="/icons/pythonLogo.webp" alt="pythonLogo" /></span>
            <span><img src="/icons/reactLogo.webp" alt="reactLogo" /></span>
            <span><img src="/icons/reduxLogo.webp" alt="reduxLogo" /></span>
            <span><img src="/icons/sonarcubeLogo.webp" alt="sonarcubeLogo" /></span>
            <span><img src="/icons/springbootLogo.webp" alt="springbootLogo" /></span>
            <span><img src="/icons/sqlLogo.webp" alt="sqlLogo" /></span>
            <span><img src="/icons/swaggerLogo.webp" alt="swaggerLogo" /></span>
            <span><img src="/icons/threejsLogo.webp" alt="threejsLogo" /></span>
            <span><img src="/icons/tsedLogo.webp" alt="tsedLogo" /></span>		
		</div>
	</div>
    )
}

export default Skills