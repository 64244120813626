import React, {useState} from 'react'
import './Navbar.css'

function Navbar() {
    const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
    
    const toggleMobileNav = () => {
        setIsMobileNavOpen(!isMobileNavOpen);
    };

    const scrollToSectionAbout = (event) => {
        event.preventDefault();
        if (isMobileNavOpen) {
            setIsMobileNavOpen(!isMobileNavOpen);
        }
        const aboutSection = document.querySelector('#about');
        aboutSection.scrollIntoView({ behavior: 'smooth' });
    }

    const scrollToSectionProjects = (event) => {
        event.preventDefault();
        if (isMobileNavOpen) {
            setIsMobileNavOpen(!isMobileNavOpen);
        }
        const projectsSection = document.querySelector('#projects');
        projectsSection.scrollIntoView({ behavior: 'smooth' });
    }

    const scrollToSectionContact = (event) => {
        event.preventDefault();
        if (isMobileNavOpen) {
            setIsMobileNavOpen(!isMobileNavOpen);
        }
        const contactSection = document.querySelector('#contact');
        contactSection.scrollIntoView({ behavior: 'smooth' });
    }
  
    return (
        <nav className={`nav ${isMobileNavOpen ? 'nav--open' : 'nav--closed'}`} role='navigation'>
        <div className='nav__content full'>
            <ul id='navbar-desktop-left' className='nav__desktop nav__desktop--left'>
                <li id="menu-item-about" className="">
                    <a href="#about" onClick={scrollToSectionAbout} aria-current="page" className="cursor-hover navbar-hover">A propos</a>
                </li>
                <li id="menu-item-projects" className="">
                    <a href="#projects" onClick={scrollToSectionProjects} className="cursor-hover navbar-hover">Projets</a>
                </li>
            </ul>
            <a className="site-logo cursor-hover" href="/" title="Arthur Boron">
				<img src='images/arthur_boron-logo.webp' alt='logo'/>
			</a>

            <ul id="menu-desktop-right" className="nav__desktop nav__desktop--right">
                <li id="menu-item-skills" className="">
                    <a href="https://www.linkedin.com/in/arthur-boron/" target="_blank" rel='noreferrer' aria-current="page" className="cursor-hover navbar-hover">Linkedin</a>
                </li>
                <li id="menu-button-contact" className="button">
                    <a href="#contact" onClick={scrollToSectionContact} className="cursor-hover">Contact</a>
                    <span>
                        <span>
                            <a href="#contact" onClick={scrollToSectionContact} className="cursor-hover">Contact</a>
                        </span>
                        <span>
                            <a href="#contact" onClick={scrollToSectionContact} className="cursor-hover">Contact</a>
                        </span>
                    </span>
                </li>
            </ul>

            <ul id="menu-mobile" className="nav__mobile" style={{ display: isMobileNavOpen ? 'flex' : 'none' }}>
                <li id="menu-item-mobile-about" className="">
                    <a href="#about" onClick={scrollToSectionAbout} className="cursor-hover">A propos</a>
                </li>
                <li id="menu-item-mobile-contact" className="">
                    <a href="#contact" onClick={scrollToSectionContact} className="cursor-hover">Contact</a>
                </li>
            </ul>
            <button className="nav__icon" title="mobileNavbarMenu" onClick={toggleMobileNav}>
                <div>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </button>
            <ul className="icon-list" style={{ display: isMobileNavOpen ? 'flex' : 'none' }}>
				<li>
                    <a className="icon-list__linkedin cursor-hover" href="https://www.linkedin.com/in/arthur-boron/" target="_blank" rel="noreferrer">LinkedIn</a>
                </li>
	        </ul>
        </div>
      </nav>
    )
}

export default Navbar
  