import React from "react";
import './StackingCards.css'

function StackingCards (props) {
    const { id, title, description, img, alt } = props;

    return (
        <section id={id} className="text-block full loaded" data-watch="true">
            <div className="text-block__half text-block__half--illustration appear">
                <img width="1024" height="1024" src={img} alt={alt} decoding="async" loading="lazy"/>
            </div>
            <div className="text-block__half appear">
                <h3>{title}</h3>
                <p>{description}</p>
            </div>
        </section>
    )
}

export default StackingCards