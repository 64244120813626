import React from "react";
import './PreContact.css'

function PreContact() {

    const scrollToSectionContact = (event) => {
        event.preventDefault();
        const contactSection = document.querySelector('#contact');
        contactSection.scrollIntoView({ behavior: 'smooth' });
    }

    return (
        <section className="contact loaded" data-watch="true">
            <div className="contact__wrapper">
                <div className="contain appear">
                    <h4>Intrigués ?</h4>
                    <p>
                        <a onClick={scrollToSectionContact} className="button cursor-hover" href="#contact">
                            Discutons
                            <span>
                                <span>Discutons</span>
                                <span>Discutons</span>
                            </span>
                        </a>
                    </p>
                </div>
                <img className="contact__image" src="/icons/intrigued.webp" alt="Intrigué ?" />
            </div>
        </section>
    )
}

export default PreContact