import './App.css';
import Contact from './components/contact/Contact';
import Header from './components/hero/Header';
import Navbar from './components/navbar/Navbar';
import PreContact from './components/pre-contact/PreContact';
import Projects from './components/projects/Projects';
import Skills from './components/skills/Skills';
import StackingCards from './components/stacking-card/StackingCards';
import Ticker from './components/ticker/Ticker';

function App() {
  return (
    <div className="App">
      <Navbar />
      <Header />
      <main>
        <Ticker />
        <Skills />
        <StackingCards id="about" title="Curiosité Technique" description="Curieux, j'ai un intérêt constant pour les nouvelles technologies, les frameworks et les tendances du développement web." img="./icons/question.webp" alt="Affaires et finances icônes créées par Freepik - Flaticon"/>
        <StackingCards title="Capacité à travailler en équipe" description="Je collabore efficacement avec les membres de l'équipe pour réaliser des projets de manière efficace et harmonieuse." img="./icons/travail-en-equipe.webp" alt="Affaires et finances icônes créées par Freepik - Flaticon"/>
        <StackingCards title="Esprit d'initiative" description="Je suis proactif dans la recherche de solutions, d'améliorations et d'innovations dans les projets que je mène." img="./icons/initiative.webp" alt="Affaires et finances icônes créées par Freepik - Flaticon"/>
        <StackingCards title="Compétences en communication" description="Je communique clairement avec les membres de l'équipe, les clients et utilisateurs finaux." img="./icons/appel-telephonique.webp" alt="Affaires et finances icônes créées par Freepik - Flaticon"/>
        <Projects id="projects"/>
        
      </main>
      <PreContact />
      <Contact />
    </div>
  );
}

export default App;
