import React from "react";
import './Project.css'

function Project(props) {
    const {title, alt, img, description} = props
    return (
        <article className="tile appear">

                <div className="tile__container">
                    <div className="tile__content">

                        <div className="thefront">
                            <h4 className="cursor-hover">{title}</h4>
                            <img className="imgProject" src={img} alt={alt} />
                        </div>

                        <div className="theback">
                            <h1 className="backCardTitle">{title}</h1>
                            <p className="backCardText">{description}</p>
                        </div>
                    </div>    
                </div>
        </article>	
    )
}

export default Project