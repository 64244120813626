import React from "react";
import './Projects.css'
import Project from "../project/Project";

function Projects(props) {
    const {id} = props
    return (
        <section id={id} className="tiles loaded" data-watch="true">

		<div className="projectsTitle full">
           <img src="/images/projectsTitle.webp" alt="Mes projets" />
        </div>
        <div className="tiles__wrapper full">
            <Project title={'Monique'} alt={"Monique_preview"} img={"/images/projetMonique.webp"} description={"Création d'un tableau de bord de modération pour un streamer influent avec plus de 175 000 abonnés. Utilisation de Angular, Node.js, Tsed, PostgreSQL, RabbitMQ et JWT pour une authentification sécurisée."}/>
            <Project title={'Back to Basics'} alt={"Back to Basics_preview"} img={"/images/projetBTB.webp"} description={"Application front-end Angular responsive interrogeant l'API MovieDb pour afficher les informations sur les films, séries et acteurs, avec une fonction de recherche intégrée."}/>
            <Project title={'Page with Lockscreen'} alt={"Page with Lockscreen_preview"} img={"/images/projetPWL.webp"} description={"Mini projet single-page HTML/JS/CSS avec verrouillage/déverrouillage et affichage d'infos dans des cartes. Réactif et stylisé avec des effets de glissement (drag)."}/>
            <Project title={'Kanban d&d'} alt={"Kanban d&d_preview"} img={"/images/projetKanban.webp"} description={"Projet React Kanban utilisant le local storage pour stocker les informations modifiées. Il permet d'ajouter des colonnes et des tâches à accomplir, et utilise un système de glisser-déposer (drag and drop)."}/>
            <Project title={'Ip tracker'} alt={"Ip tracker_preview"} img={"/images/projetIpTracker.webp"} description={"Mini projet HTML/JS/CSS intégrant la bibliothèque LeafletJS. Il offre la possibilité de saisir une adresse IP ou un nom de serveur, et affiche la localisation correspondante sur une carte interactive."}/>
            <Project title={'Softia'} alt={"Softia_preview"} img={"/images/projetSoftia.webp"} description={"Projet d'OSINT avec Curl et Selenium pour la recherche des informations publiques sur les réseaux sociaux liées à une personne. Utilisation d'un avatar virtuel via une application Unreal Engine avec des blueprints."}/>
            <Project title={'ThreeJS journey'} alt={"ThreeJS journey_preview"} img={"/images/projet3JS.webp"} description={"Participation à la formation \"Three.js Journey\" créée par Bruno Simon, comprenant plusieurs mini-projets. Apprentissage approfondi de Three.js et de React Three Fiber."}/>
            <Project title={'Portfolio'} alt={"Portfolio_preview"} img={"/images/projetPortfolio.webp"} description={"Portfolio React mettant en avant mes compétences en développement web. Projets démontrant ma maîtrise de React pour des interfaces interactives."}/>
            <Project title={'Meal Mate'} alt={"Meal Mate_preview"} img={"/images/projetMealMate.webp"} description={"Projet en cours : Application full stack permettant d'organiser ses recettes de la semaine tout en tenant compte des dates de péremption des aliments. "}/>
		</div>

	</section>
    )
}

export default Projects