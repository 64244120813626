import React, {useEffect, useState} from 'react'
import './Header.css'

function Header() {
    const [loaded, setLoaded] = useState(false);
    
    useEffect(() => {
          setLoaded(true);
      }, []);
  
    return (
        <header className={`intro ${loaded ? 'loaded' : ''}`} data-watch="true">
            <div className="headerFull">
                <h1 className="intro__title1 noselect appear">
                    Arthur
                </h1>
                <div className="intro__image noselect appear delay">
                    <span className="squiggle squiggle1"></span>
                    <span className="squiggle squiggle2"></span>
                    <span className="squiggle squiggle3"></span>
                    <picture>
                        <img src="/images/arthur_boron.webp" alt="arthur-boron" />
                    </picture>
                </div>
                <h1 className="intro__title2 noselect appear delay">Boron</h1>
            </div>
        </header>
    )
}

export default Header
  