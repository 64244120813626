import React, { useRef, useState } from "react";
import emailjs from '@emailjs/browser';
import './Contact.css'

function Contact() {

    const form = useRef();
    const [isEmailValidated, setIsEmailValidated] = useState(true);

    const sendEmail = (e) => {
        e.preventDefault();
        setIsEmailValidated(true)
        const email = e.target.user_email.value;

        if (!isEmailValid(email)) {
            setIsEmailValidated(false)
            return;
        }
        emailjs.sendForm('service_oyegj7l', 'template_jlmq3op', form.current, 'l8MZpFrmo97xYPmUY')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
        e.target.reset();

    };

    const isEmailValid = (email)  => {
        // Expression régulière pour valider le format de l'e-mail
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        
        return emailRegex.test(email);
    }

    return (
        <footer id="contact" role="contentinfo" data-watch="true" className="loaded">
            <div className="full footer__content footer__content--first">
                <div className="hidden">
                   <img src="/images/contactTitle.webp" alt="On discute ?" />
                </div>
            </div> 

            <div className="contactform">
                <h2 className={ isEmailValidated ? 'emailValidated' : 'emailNonValidated'}>Un problème est survenu, veuillez vérifier votre email.</h2>
                <form ref={form} onSubmit={sendEmail}>
                    <div className="nameEmailDiv">
                        <input type="text" placeholder="Qui es tu ?" name="user_name" required/>
                        <input type="email" placeholder="Quel est ton email ?" name="user_email" required/>
                    </div>
                    <div>
                        <textarea name="message" rows="3" placeholder="message" required/>
                    </div>
                    <div>
                        <input type="submit" value="Envoyer" />
                    </div>
                    
                </form>
            </div>

            <div className="full footer__content footer__content--last">

                <ul className="icon-footer">
                    <li className="icon-footer-li">
                        <a className="icon-footer__github cursor-hover" href="https://github.com/Arthur-Boron" target="_blank" rel="noreferrer" >Github</a>
                    </li>
                    <li className="icon-footer-li">
                        <a className="icon-footer__linkedin cursor-hover" href="https://www.linkedin.com/in/arthur-boron/" target="_blank" rel="noreferrer" >LinkedIn</a>
                    </li>
                </ul>

                <div className="footer__credits">
                    <p className="site-credit caps">© Arthur BORON 2023</p>
                </div>
            </div>
	    </footer>
    )
}

export default Contact