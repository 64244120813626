import React from 'react'
import './Ticker.css'

function Ticker() {
  
    return (
        <div className="ticker">
            <div className="ticker__tape">
                <span>Détective Curieux</span>
                <span>Funambule de l'adaptabilité</span>
                <span>Architecte d'un travail d'équipe efficace</span>
                <span>Détective Curieux</span>
                <span>Funambule de l'adaptabilité</span>
                <span>Architecte d'un travail d'équipe efficace</span>
                <span>Détective Curieux</span>
                <span>Funambule de l'adaptabilité</span>
                <span>Architecte d'un travail d'équipe efficace</span>
            </div>
            <div className="ticker__tape">
                <span>Détective Curieux</span>
                <span>Funambule de l'adaptabilité</span>
                <span>Architecte d'un travail d'équipe efficace</span>
                <span>Détective Curieux</span>
                <span>Funambule de l'adaptabilité</span>
                <span>Architecte d'un travail d'équipe efficace</span>
                <span>Détective Curieux</span>
                <span>Funambule de l'adaptabilité</span>
                <span>Architecte d'un travail d'équipe efficace</span>
            </div>
        </div>
    )
}

export default Ticker
  